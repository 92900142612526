export default `# Privacy Policy for FreeGuard VPN

**Effective Date:** 2025.01.22

FreeGuard VPN ("we" "our" or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and protect your information when you use our app.

---

## 1. Information We Collect

We may collect the following types of information:

### 1.1 Personal Information

- Information you provide during account registration, such as your email address or payment details for subscriptions.
- Customer support inquiries or feedback.

### 1.2 Non-Personal Information

- Device information (e.g., model, operating system, unique device identifiers).
- App usage data, including crash reports and performance metrics.

### 1.3 Advertising Data

- To deliver personalized ads, our third-party partners collect data such as:
  - Device identifiers (e.g., IDFA).
  - Behavioral data (e.g., app interactions).

---

## 2. How We Use Your Information

We use the information collected for the following purposes:

- To provide and optimize VPN services, including custom acceleration for TikTok, Steam, and other platforms.
- To manage subscriptions, including billing and renewal through Apple’s in-app purchase system.
- To deliver personalized advertising experiences.
- To analyze app performance and resolve technical issues.

---

## 3. Advertising and SDKs

Our app integrates with the following third-party advertising SDKs:

- **Liftoff**
- **Meta Audience Network**
- **Yandex Ads**
- **TopOn AdX**

These SDKs may collect data such as device identifiers, app usage, and behavioral data to deliver tailored ads. We ensure these partners comply with relevant privacy laws, including the General Data Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA).

You can manage your advertising preferences by disabling personalized ads in your device settings:

- **iOS:** Settings > Privacy > Tracking > Disable “Allow Apps to Request to Track.”

---

## 4. Subscription Services

We offer subscription-based services with the following terms:

- Subscriptions are managed through Apple’s in-app purchase system.
- Payment details are processed securely via Apple and are not stored by us.
- Users can manage or cancel subscriptions through the App Store settings.

---

## 5. Data Sharing

We do not sell your personal information to third parties. We may share limited data with third-party partners for:

- Providing core functionality (e.g., subscription management via Apple).
- Delivering personalized advertisements.

---

## 6. Data Security

We use industry-standard encryption and security measures to protect your data. However, no method of transmission over the internet or electronic storage is 100% secure.

---

## 7. User Rights

Depending on your location, you may have the following rights:

- **Access and Portability:** Request a copy of your data.
- **Deletion:** Request deletion of your data, subject to legal and operational requirements.
- **Opt-Out:** Opt out of personalized advertising.

To exercise these rights, contact us at **support@planetlinkinc.com**.

---

## 8. Changes to This Privacy Policy

We may update this Privacy Policy from time to time. Changes will be posted within the app and take effect immediately.

---

## 9. Contact Us

If you have any questions about this Privacy Policy, please contact us at:
**Email:** [support@planetlinkinc.com](mailto:support@planetlinkinc.com)`;
